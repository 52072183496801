import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const createProductApi = createApi({
  reducerPath: 'createProductApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['CreateProduct'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (data) => {
        return {
          url: `create_products?page=${data.page}&size=${data.size}&searchid=${data.searchid}`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['CreateProduct'],
    }),


    create: builder.mutation({
      query: (data) => ({
        url: `create_products`,
        method: 'POST',
        body: data,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['CreateProduct'],
    }),

    start: builder.mutation({
      query: (newPost) => ({
        url: `create_products/${data.id}`,
        method: 'POST',
        body: newPost,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': localStorage.getItem('userToken'),
          'X-User-Id': localStorage.getItem('userId'),
          'X-User-Role': localStorage.getItem('userRole'),
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['CreateProduct'],
    }),

    update: builder.mutation({
      query: (data) => {
        return {
          url: `create_products/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['CreateProduct'],
    }),

    abort: builder.mutation({
      query: (data) => {
        return {
          url: `create_products/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['CreateProduct'],
    }),

    storeList: builder.query({
      query: (data) => {
        return {
          url: `stores/store/list`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['CreateProduct'],
    }),


  }),

})

export const { useFetchQuery, useStoreListQuery, useCreateMutation, useUpdateMutation, useAbortMutation, useStartMutation } = createProductApi