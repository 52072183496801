import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Menu as Menus } from 'antd';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { removeToLanguage, removeToCart } from "../../reducers/authReducer";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InventoryIcon from '@mui/icons-material/Inventory';
import StoreIcon from '@mui/icons-material/Store';
import { useForm } from "react-hook-form";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import QueueIcon from '@mui/icons-material/Queue';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

function HeaderMenu(props) {
  const dispatch = useDispatch();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  function handleClick(e) {
    console.log('', e);
  }
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();


  function userLogout() {
    dispatch(removeToCart());
    history.push(`/`);
  }

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };


  function setNevActive(path) {
    if (window.location.pathname?.includes(path)) {
      return true
    } else {
      return false
    }
  }  
  

  const menu = (
    <Menu className="Header-dropdown-menu">
      <Menu.Item key="002">
        <Link to="#" style={{ fontSize: '16px' }}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="004">
        <Link to="#" style={{ fontSize: '16px' }} onClick={userLogout}>Sign out</Link>
      </Menu.Item>
    </Menu>
  );


  function displayMenu() {
    const getName = new URLSearchParams(location.search).get('name')
    return (
      <div className="dFlex mobile-hide" style={{ margin: '0 10px' }}>
        <div className="dFlex" style={{ width: '100%', whiteSpace: 'pre', fontSize: '1.1rem', paddingLeft: '0px', textTransform: 'capitalize', color: '#9f9f9f' }}>
          <span>{
            window.location.pathname?.replace(/-/g, ' ')?.replace('/', ' ') || 'Board'
          }</span>
          <small style={{ display: 'flex', alignItems: 'center', color: '#838383' }}>
            {getName && <><KeyboardArrowRightIcon /> {getName}</>}
          </small>
        </div>
      </div>
    )
  }


  function onSubmit(data) {
    history.push(`/product/${data.product}`);
    reset()
  }


  return (
    <div className="ML-Header">

      <Menus onClick={handleClick} mode="vertical"
        inlineCollapsed={props.collapsed} className="sideMenuN">

        <Button
          component={Link}
          to={{ pathname: "/google-merchants", state: { from: "root" } }}
         className={`menuColor ${setNevActive('google-merchant') && `thiActive`}`}
        >
          <span><StoreIcon className="sideMenuIcon" /></span>
          {!props.collapsed &&
            <span>Google Merchant</span>
          }
        </Button>

        <Button
          component={Link}
          to={{ pathname: "/stores", state: { from: "root" } }}
          className={`menuColor ${setNevActive('store') && `thiActive`}`}
        >
          <span><StorefrontIcon className="sideMenuIcon" /></span>
          {!props.collapsed &&
            <span>Stores</span>
          }
        </Button>

        <Button
          component={Link}
          to={{ pathname: "/products", state: { from: "root" } }}
          className={`menuColor ${setNevActive('product') && `thiActive`}`}
        >
          <span><InventoryIcon className="sideMenuIcon" /></span>
          {!props.collapsed &&
            <span>Products</span>
          }
        </Button>

        <Button
          component={Link}
          to={{ pathname: "/team", state: { from: "root" } }}
          className={`menuColor ${setNevActive('team') && `thiActive`}`}
        >
          <span><PeopleAltIcon className="sideMenuIcon" /></span>
          {!props.collapsed &&
            <span>Team</span>
          }
        </Button>

        <Button
          component={Link}
          to={{ pathname: "/create", state: { from: "root" } }}
          className={`menuColor ${setNevActive('create') && `thiActive`}`}
        >
          <span><QueueIcon className="sideMenuIcon" /></span>
          {!props.collapsed &&
            <span>Create Products</span>
          }
        </Button>

 

      </Menus>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          <div>
            <span style={{ color: '#5e6e82' }} className={`syncIcon`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              <IconButton>
                {React.createElement(MenuIcon)}
              </IconButton>
            </span>
          </div>
          <div className='mobile-hide' style={{ fontSize: '1.2rem', margin: '0 10px', whiteSpace: 'nowrap' }}>
            <div>Merchant Centre</div>
          </div>
          {displayMenu()}
        </div>
        <div className="headerRightCard">
          <form onSubmit={handleSubmit(onSubmit)} className="table_search_card">
            <input
              type="text"
              className="form-control table_search"
              placeholder="Search for product..."
              id="product"
              aria-invalid={errors.product ? "true" : "false"}
              {...register("product", { required: true })}
            />
            <IconButton aria-haspopup="true" type="submit" className="table_search_icon">
              <SearchIcon style={{ fontSize: 'inherit' }} />
            </IconButton>
          </form>

          <Dropdown>
            <MenuButton
              style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
              className="Header-button-connect">
              <img src='https://lit.ebtida.com/user-thumbnail.png' style={{ width: '35px', borderRadius: '100px' }} />
            </MenuButton>
            <Menu>
              <div style={{ padding: '10px 15px 10px 15px' }}>{userEmail}</div>
              <MenuItem onClick={(() => userLogout())} key="2"  >
                <div style={{ fontSize: '1rem' }}>Logout</div>
              </MenuItem>
            </Menu>
          </Dropdown>

        </div>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
