import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetStoresQuery, useCreateStoreMutation } from '../../services/store';
import Moment from 'moment';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TableSkeleton from '../TableSkeleton';
import LinearProgress from '@mui/material/LinearProgress';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';


function Stores() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const type = new URLSearchParams(location.search).get('type') || 'active'

  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }

  const storesInfo = useGetStoresQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    type: type,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const stores = storesInfo?.data?.stores;
  const pagination = storesInfo?.data?.pagination;


  const [createUser, createresult] = useCreateStoreMutation()

  function createFunction(data) {
    createUser({
      name: data?.name,
      country: data?.country,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      active: type === 'draft' ? false : true
    })
  }


  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }

  }, [createresult]);

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">

      <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeNewModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit(createFunction)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control"
                    id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Country</label>
                  <select className="form-control"
                    id="country"
                    aria-invalid={errors.country ? "true" : "false"}
                    {...register('country', { required: true })}>
                    <option value="" selected disabled>Select Country</option>
                    <option value="AE">UAE</option>
                    <option value="SA">Saudi</option>
                    <option value="GB">UK</option>
                    <option value="OM">Oman</option>
                    <option value="KW">Kuwait</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button
                    style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                    disabled={createresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="small"
                    type="submit">
                    {createresult.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={storesInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <input style={{ borderRadius: '100px' }} className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                  {/* {storesInfo?.isFetching &&
                    <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                  } */}
                  <SearchIcon className="table_search_icon" />
                </div>

                <div style={{ margin: '0px 10px' }}>
                  <Dropdown>
                    <MenuButton
                      style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontWeight: 500 }}
                      className="Header-button-connect">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Filter:&nbsp; {type}</span>
                        <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                      </div>
                    </MenuButton>
                    <Menu>
                      <MenuItem component={Link} to={`${window.location.pathname}?type=draft`}>Draft Store</MenuItem>
                      <MenuItem component={Link} to={`${window.location.pathname}?type=active`}>Active Store</MenuItem>
                    </Menu>
                  </Dropdown>
                </div>

              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => ebtidaEModal()}>
                    Add New
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(storesInfo?.isLoading, storesInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Country</th>
                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                    Update on
                  </th>
                </tr>
              </thead>
              {stores?.length === 0 ?
                <h5 className="data_not-found">No data available</h5>
                : null}
              <tbody>
                {/* <>
                  {storesInfo?.isLoading &&
                    TableSkeleton(4)
                  }
                </> */}
                {stores?.map((item, i) =>
                  <tr key={i}>
                    <td>
                      <div>{item.id}</div>
                    </td>
                    <td>
                      <Link to={`/store/${item.id}`}>
                        <div> {item.name}</div>
                      </Link>
                    </td>
                    <td>
                      <div>{item.country}</div>
                    </td>
                    <td className="sticky-column" style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                      </div>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>

        </div>
      </div>
      {!storesInfo?.isLoading &&
        <>
          {storesInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{storesInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default Stores;
