import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useFetchGoogleMerchantsQuery, useCreateGoogleMrchantAccountMutation, useUpdateGoogleMerchantAccountMutation, useDeleteProductMutation, useSyncProductsMutation } from '../../services/google_merchant';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import EditIcon from '@mui/icons-material/Edit';
import TableSkeleton from '../TableSkeleton';
import LinearProgress from '@mui/material/LinearProgress';
import { useStoreListQuery } from '../../services/create_products';
function GoogleMerchants() {
  const { userId, userToken, userRole, userEmail, userName, status, admin, accessKey } = useSelector((state) => state.auth);


  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const storeListData = useStoreListQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const activeStore = storeListData?.data?.stores?.active;


  const productInfo = useFetchGoogleMerchantsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, accessKey: accessKey }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const googleMerchants = productInfo?.data?.google_merchants;
  const pagination = productInfo?.data?.pagination;

  const [createNew, createresult] = useCreateGoogleMrchantAccountMutation()
  const [deleteList, deleteresult] = useDeleteProductMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);

  const [getMerchantId, setMerchantId] = useState();
  const [getDomain, setDomain] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getAccountName, setAccountName] = useState("")
  const [getAnalyticId, setAnalyticId] = useState(0)
  const [getLimits, setLimits] = useState("")
  const [getID, setID] = useState("")

  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setMerchantId(data.merchant_id)
    setDomain(data.domain)
    setCountry(data.country)
    setAccountName(data.account_name)
    setAnalyticId(data.analytics_id)
    setLimits(data.limits)
    setID(data.id)
  }

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [updatePost, updateresult] = useUpdateGoogleMerchantAccountMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
      reset()
    }
  }, [updateresult, createresult]);

  function createNewPost(data) {
    createNew({
      merchant_id: data.merchant_id,
      domain: data.domain,
      country: data.country,
      account_name: data.account_name,
      analytics_id: data.analytics_id,
      limits: data.limits,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const updateData = {
    id: getID,
    merchant_id: getMerchantId,
    domain: getDomain,
    country: getCountry,
    account_name: getAccountName,
    analytics_id: getAnalyticId,
    limits: getLimits,
    userId: userId,
    userToken: userToken,
    admin: admin
  }


  const [createsync, syncresult] = useSyncProductsMutation()
  const [getStoreId, setStoreId] = useState();
  const [getSyncModal, setSyncModal] = useState(false);
  const [getSyncData, setSyncData] = useState(false);

  function syncGoogleMerchant(data) {
    setSyncModal(true)
    setSyncData(data)
  }

  function closeSyncModal(data) {
    setSyncModal(false)
  }
  useEffect(() => {
    if (syncresult?.isSuccess) {
      setSyncModal(false)
      setStoreId()
    }
  }, [syncresult]);

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">

      <Modal className="sync-modal" show={getSyncModal} onHide={closeSyncModal} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeSyncModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h6 style={{ textAlign: 'center', padding: '10px' }}>Sync products to google merchants</h6>
            </div>
          </div>
          <form onSubmit={handleSubmit(createNewPost)}>
            <div className="row">

              <div className="col-md-12">
                <div className="form-group">
                  <div>
                    <div>Merchant Id: {getSyncData?.merchant_id}</div>
                    <div>Domain: {getSyncData?.domain}</div>
                    <div>Country: {getSyncData?.country}</div>
                    <div>Store Id {getStoreId}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Store</label>
                  {/* <input type="text"
                    className="form-control"
                    defaultValue={getStoreId}
                    onChange={(e) => setStoreId(e.target.value)} /> */}
                  <select style={{ height: '45px' }} className='form-control' onChange={(e) => setStoreId(e.target.value)}>
                    <option selected disabled>Select Store</option>
                    {activeStore?.map((item, i) =>
                      <option value={item.id}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button disabled={!getStoreId}
                    color="info"
                    variant="contained"
                    size="small"
                    style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                    onClick={(() => getStoreId && createsync({
                      id: getSyncData?.id,
                      merchantId: getSyncData?.merchant_id,
                      country: getSyncData?.country,
                      limits: getSyncData?.limits,
                      domain: getSyncData?.domain,
                      storeId: getStoreId,
                      userId: userId,
                      userToken: userToken,
                      admin: admin
                    }))}
                  >
                    {'Sync Now'}
                  </Button>
                </div>
              </div>
            </div>
          </form >
        </Modal.Body >
      </Modal>



      <Modal className="new-post-modal" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New Product</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit(createNewPost)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Merchant ID</label>
                  <input type="text" className="form-control" id="merchant_id"
                    aria-invalid={errors.merchant_id ? "true" : "false"}
                    {...register('merchant_id', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Domain</label>
                  <input type="text" className="form-control" id="domain"
                    aria-invalid={errors.domain ? "true" : "false"}
                    {...register('domain', { required: true })} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Country</label>
                  <select className="form-control" id="country"
                    aria-invalid={errors.country ? "true" : "false"}
                    {...register('country', { required: true })}>
                    <option value={''} selected disabled>Select</option>
                    <option value="AE">UAE</option>
                    <option value="IN">India</option>
                    <option value="PK">Pakistan</option>
                    <option value="KW">Kuawait</option>
                    <option value="OM">Oman</option>
                    <option value="BH">Nahrain</option>
                    <option value="UK">UK</option>
                    <option value="QA">Qatar</option>
                    <option value="SA">Saudi</option>
                    <option value="AU">Australia</option>
                    <option value="CA">Canada</option>
                    <option value="NZ">New Zealand</option>
                    <option value="IE">Ireland</option>
                    <option value="ZA">South Africa</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Account Name</label>
                  <input type="text" className="form-control" id="account_name"
                    aria-invalid={errors.account_name ? "true" : "false"}
                    {...register('account_name', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Analytic ID</label>
                  <input type="text" className="form-control" id="analytics_id"
                    aria-invalid={errors.analytics_id ? "true" : "false"}
                    {...register('analytics_id', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>limits</label>
                  <input type="number" className="form-control" id="limits"
                    aria-invalid={errors.limits ? "true" : "false"}
                    {...register('limits', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button disabled={createresult?.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="small"
                    style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                    type="submit">
                    {createresult?.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>

          </form >
        </Modal.Body >
      </Modal>


      <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label>Merchant ID</label>
                <input type="text" className="form-control" defaultValue={getMerchantId} onChange={(e) => setMerchantId(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Domain</label>
                <input type="text" className="form-control" defaultValue={getDomain} onChange={(e) => setDomain(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Country</label>
                <select className="form-control" defaultValue={getCountry} onChange={(e) => setCountry(e.target.value)}>
                  <option value={''} selected disabled>Select</option>
                  <option value="AE">UAE</option>
                  <option value="IN">India</option>
                  <option value="PK">Pakistan</option>
                  <option value="KW">Kuawait</option>
                  <option value="OM">Oman</option>
                  <option value="BH">Nahrain</option>
                  <option value="UK">UK</option>
                  <option value="QA">Qatar</option>
                  <option value="SA">Saudi</option>
                  <option value="AU">Australia</option>
                  <option value="CA">Canada</option>
                  <option value="NZ">New Zealand</option>
                  <option value="IE">Ireland</option>
                  <option value="ZA">South Africa</option>
                  <option value="DE">Germany</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Account Name</label>
                <input type="text" className="form-control" defaultValue={getAccountName} onChange={(e) => setAccountName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Analytic ID</label>
                <input type="text" className="form-control" defaultValue={getAnalyticId} onChange={(e) => setAnalyticId(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>limits</label>
                <input type="number" className="form-control" defaultValue={getLimits} onChange={(e) => setLimits(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button disabled={updateresult?.isLoading ? true : false}
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                  onClick={() => { updatePost(updateData) }}
                >
                  {updateresult?.isLoading ? 'Loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>





      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                </div>
              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053', margin: '0 10px' }}
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>
                </div>
                <div>
                  {/* <Button
                    disabled
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => imageUpdate()}>
                    Import CSV
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(productInfo?.isLoading, productInfo?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th>Merchant ID</th>
                  <th>Domain</th>
                  <th>Country</th>
                  <th>Account Name</th>
                  <th>Analytic ID</th>
                  <th>Limits</th>
                  <th>Success</th>
                  <th>Errors</th>
                  <th>State</th>
                  <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>Manage</th>
                </tr>
              </thead>
              {/* {productInfo?.isLoading &&
                TableSkeleton(10)
              } */}
              <tbody>
                {googleMerchants?.map((item, i) =>
                  <tr>
                    <td>
                      <IconButton
                        onClick={() => handleOpenEdit(item)}
                      >
                        <EditIcon style={{ fontSize: '12px' }} />
                      </IconButton>
                      <Link to={`/google-merchant/${item.merchant_id}`} style={{ whiteSpace: 'initial' }}>
                        {item.merchant_id}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {item.domain}
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.country}
                      </div>
                    </td>
                    <td>{item.account_name}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.analytics_id}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.limits}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.success}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.errors}</td>
                    <td style={{ whiteSpace: 'initial' }}>{item.state}</td>
                    <td className="sticky-column">
                      <div className="dFlex center nowrap jcr">
                        <Button
                          onClick={() => syncGoogleMerchant(item)}
                          color={`error`}
                          variant={`outlined`}
                          size="small"
                          disabled={false}
                        >
                          <small className="dFlex">{`Start Sync`}</small>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {googleMerchants?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }

        </div>
      </div>
      <br /><br />

      {!productInfo?.isLoading &&
        <>
          {productInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{productInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div >
  );
}

export default GoogleMerchants;
