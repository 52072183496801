import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useStoreProductsQuery, useDeleteMutation, useCreateMutation, useUpdateMutation, useUpdateFileMutation, useRefetchProductsMutation, useRefetchIndexQuery, useRefetchUpdateMutation, useSplitProductsMutation } from '../../services/products';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CKEditor } from 'ckeditor4-react';
import ImageUpdate from "./ImageUpdate";
import CloseIcon from '@mui/icons-material/Close';
import parse from "html-react-parser";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function Store() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const suppliersData = useStoreProductsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data?.products;
  const pagination = suppliersData?.data?.pagination;

  const [createNew, createresult] = useCreateMutation()

  const [getSplitLimit, setSplitLimit] = useState();
  const [getSplitStore, setSplitStore] = useState();
  const [splitproducts, splitresult] = useSplitProductsMutation()

  const refetchData = useRefetchIndexQuery({ userId: userId, userToken: userToken, admin: admin, store_id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const [refetchcreate, refetchresult] = useRefetchProductsMutation()
  const [refetchupdate, refetchupdateresult] = useRefetchUpdateMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);


  const [getTitle, setTitle] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getColor, setColor] = useState("")
  const [getWeight, setWeight] = useState(0)
  const [getQuantity, setQuantity] = useState("")
  const [getBrand, setBrand] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getInStock, setInStock] = useState("")
  const [getTags, setTags] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getCategories, setCategories] = useState("")
  const [getFeatures, setFeatures] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getImageUrls, setImageUrls] = useState("")
  const [getMainImage, setMainImage] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getUID, setUID] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState(0)
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setUID(data.asin)
    setCountry(data.country)
    setColor(data.color)
    setWeight(data.weight)
    setQuantity(data.quantity)
    setBrand(data.brand)
    setCondition(data.condition)
    setInStock(data.in_stock)
    setPrice(data.price)
    setCategories(data.category)
    setTags(data.tags)
    setFeatures(data.features)
    setDetails(data.details)
    setImageUrls(data.images)
    setMainImage(data.image)
    setProductUrl(data.product_url)
    setDeliveryDays(data.estimated_delivery)
    setId(data.id)

  }

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }



  const [updatePost, updateresult] = useUpdateMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);


  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }

  function createNewPost(data) {
    createNew({
      title: data.title,
      country: data.country,
      asin: data.asin,
      color: data.color,
      weight: data.weight,
      quantity: data.quantity,
      brand: data.brand,
      condition: data.condition,
      in_stock: data.in_stock,
      price: data.price,
      category: data.category,
      tags: data.tags,
      images: data.images,
      image: data.main_image,
      product_url: data.product_url,
      estimated_delivery: data.estimated_delivery,
      store_id: id,
      features: getFeatures,
      details: getDetails,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  const updateData = {
    title: getTitle,
    country: getCountry,
    color: getColor,
    weight: getWeight,
    quantity: getQuantity,
    brand: getBrand,
    condition: getCondition,
    in_stock: getInStock,
    tags: getTags,
    price: getPrice,
    category: getCategories,
    features: getFeatures,
    details: getDetails,
    images: getImageUrls,
    image: getMainImage,
    product_url: getProductUrl,
    estimated_delivery: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }


  const [updatefile, updatefileresult] = useUpdateFileMutation()
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getUpdateImage, setUpdateImage] = useState();
  function updateBookImage() {
    updatefile({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      store_id: id,
    })
  }


  function imageUpdate(data) {
    setImageUpdateModal(true)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  useEffect(() => {
    if (updatefileresult.isSuccess) {
      setImageUpdateModal(false)
    }
    if (createresult?.isSuccess) {
      reset()
    }
  }, [updatefileresult, createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="tabel_cards">

      <div style={{ display: 'none' }}>
        <CKEditor
          style={{ display: 'none' }}
          data={``}
          config={{
            extraPlugins: "justify,showblocks",
            toolbar: [
              ['Source'],
              ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['Undo', 'Redo'],
              ['EasyImageUpload'],
              ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
              ['Maximize']
            ],
            height: '220px',
            allowedContent: false,
            direction: 'rtl',
            language: 'en',
            pasteFromWordRemoveFontStyles: false
          }}
          id="template_html"
        />
      </div>

      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        closeImageUpdate={closeImageUpdate}
        setUpdateImage={setUpdateImage}
        updatefileresult={updatefileresult}
        updateBookImage={updateBookImage}
        getUpdateImage={getUpdateImage}
      />

      <Modal className="new-post-modal" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New Product</h5>
            </div>
          </div>


          <form onSubmit={handleSubmit(createNewPost)}>
            <div className="row">
              <div className="col-md-12">

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" id="title"
                        aria-invalid={errors.title ? "true" : "false"}
                        {...register('title', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Country</label>
                      <select className="form-control" id="country"
                        aria-invalid={errors.country ? "true" : "false"}
                        {...register('country', { required: true })}>
                        <option value={'AE'} selected>UAE</option>
                        <option value={'US'}>United State</option>
                        <option value={'UK'}>United Kingdom</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Price in USD</label>
                      <input type="number" className="form-control" id="price"
                        aria-invalid={errors.price ? "true" : "false"}
                        {...register('price', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Weight in Grams</label>
                      <input type="number" className="form-control" id="weight"
                        aria-invalid={errors.weight ? "true" : "false"}
                        {...register('weight', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>UID</label>
                      <input type="text" className="form-control" id="asin"
                        aria-invalid={errors.asin ? "true" : "false"}
                        {...register('asin', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Condition</label>
                      <input type="text" className="form-control" id="condition"
                        aria-invalid={errors.condition ? "true" : "false"}
                        {...register('condition', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Color</label>
                      <input type="text" className="form-control" id="color"
                        aria-invalid={errors.color ? "true" : "false"}
                        {...register('color', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Brand</label>
                      <input type="text" className="form-control" id="brand"
                        aria-invalid={errors.brand ? "true" : "false"}
                        {...register('brand', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>In Stock</label>
                      <select className='form-control' id="in_stock"
                        aria-invalid={errors.in_stock ? "true" : "false"}
                        {...register('in_stock', { required: true })}>
                        <option value={'In Stock'} selected>In Stock</option>
                        <option value={'Out of Stock'}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Categories</label>
                      <input type="text" className="form-control" id="category"
                        aria-invalid={errors.category ? "true" : "false"}
                        {...register('category', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Tags</label>
                      <input type="text" className="form-control" id="tags"
                        aria-invalid={errors.tags ? "true" : "false"}
                        {...register('tags', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Quantity</label>
                      <input type="number" className="form-control" id="quantity"
                        aria-invalid={errors.quantity ? "true" : "false"}
                        {...register('quantity', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Delivery in Days</label>
                      <input type="number" className="form-control" id="estimated_delivery"
                        aria-invalid={errors.estimated_delivery ? "true" : "false"}
                        {...register('estimated_delivery', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Image urls</label>
                      <input type="text" className="form-control" id="images"
                        aria-invalid={errors.images ? "true" : "false"}
                        {...register('images', { required: false })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Main image</label>
                      <input type="text" className="form-control" id="main_image"
                        aria-invalid={errors.main_image ? "true" : "false"}
                        {...register('main_image', { required: false })} />
                    </div>
                  </div>



                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Product URL</label>
                      <input type="text" className="form-control" id="product_url"
                        aria-invalid={errors.product_url ? "true" : "false"}
                        {...register('product_url', { required: false })} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">


                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Features</label>
                      <CKEditor
                        data={``}
                        config={{
                          extraPlugins: "justify,showblocks",
                          toolbar: [
                            ['Source'],
                            ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['EasyImageUpload'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                            ['Maximize']
                          ],
                          height: '220px',
                          allowedContent: false,
                          direction: 'rtl',
                          language: 'en',
                          pasteFromWordRemoveFontStyles: false
                        }}
                        id="template_html"
                        onChange={postFeatures}
                        initData={parse(``)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Details</label>
                      <CKEditor
                        data={``}
                        config={{
                          extraPlugins: "justify,showblocks",
                          toolbar: [
                            ['Source'],
                            ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['EasyImageUpload'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                            ['Maximize']
                          ],
                          height: '220px',
                          allowedContent: false,
                          direction: 'rtl',
                          language: 'en',
                          pasteFromWordRemoveFontStyles: false
                        }}
                        id="template_html"
                        onChange={postDetails}
                        initData={parse(``)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button disabled={createresult?.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="small"
                        style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                        type="submit">
                        {createresult?.isLoading ? 'Loading...' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control" onChange={(e) => setCountry(e.target.value)}>
                      <option value={getCountry} selected>{getCountry}</option>
                      <option value={'AE'}>UAE</option>
                      <option value={'US'}>United State</option>
                      <option value={'UK'}>United Kingdom</option>

                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Price in USD</label>
                    <input type="number" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Weight in Grams</label>
                    <input type="number" className="form-control" defaultValue={getWeight} onChange={(e) => setWeight(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>UID</label>
                    <input type="text" disabled className="form-control" defaultValue={getUID} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Condition</label>
                    <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Color</label>
                    <input type="text" className="form-control" defaultValue={getColor} onChange={(e) => setColor(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Brand</label>
                    <input type="text" className="form-control" defaultValue={getBrand} onChange={(e) => setBrand(e.target.value)} />
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="form-group">
                    <label>In Stock</label>
                    <select className='form-control' onChange={(e) => setInStock(e.target.value)}>
                      <option selected value={getInStock}>{getInStock}</option>
                      <option value={'In Stock'}>In Stock</option>
                      <option value={'Out of Stock'}>Out of Stock</option>
                    </select>

                  </div>
                </div>



                <div className="col-md-3">
                  <div className="form-group">
                    <label>Categories</label>
                    <input type="text" className="form-control" defaultValue={getCategories} onChange={(e) => setCategories(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Tags</label>
                    <input type="text" className="form-control" defaultValue={getTags} onChange={(e) => setTags(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Quantity</label>
                    <input type="number" className="form-control" defaultValue={getQuantity} onChange={(e) => setQuantity(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Delivery in Days</label>
                    <input type="number" className="form-control" defaultValue={getDeliveryDays} onChange={(e) => setDeliveryDays(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Image urls</label>
                    <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Main image</label>
                    <input type="text" className="form-control" defaultValue={getMainImage} onChange={(e) => setMainImage(e.target.value)} />
                  </div>
                </div>



                <div className="col-md-12">
                  <div className="form-group">
                    <label>Product URL</label>
                    <input type="text" className="form-control" defaultValue={getProductUrl} onChange={(e) => setProductUrl(e.target.value)} />
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Features</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postFeatures}
                      initData={getFeatures} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Details</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '220px',
                        allowedContent: false,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postDetails}
                      initData={getDetails} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button disabled={updateresult?.isLoading ? true : false}
                      color="info"
                      variant="contained"
                      size="medium"
                      style={{ width: 'auto', height: '40px', background: '#222d45', color: '#eee' }}
                      onClick={() => { updatePost(updateData) }}
                    >
                      {updateresult?.isLoading ? 'Loading...' : 'Update'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap'
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button onClick={handleBack}><ArrowBackOutlinedIcon /></Button>
                  <div>
                    Showing {pagination?.totalCounts && pagination?.totalCounts} entries
                  </div>
                  <div style={{ margin: '0px 10px' }}>
                    <Dropdown >
                      <MenuButton
                        disabled
                        style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontWeight: 500 }}
                        className="Header-button-connect">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>Split</span>
                          <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                        </div>
                      </MenuButton>
                      <Menu style={{ padding: '6px 15px', width: '380px', maxWidth: '100%' }}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Limit</label>
                                  <input
                                    onChange={(e) => setSplitLimit(e.target.value)}
                                    type="text"
                                    className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Store Number</label>
                                  <input
                                    onChange={(e) => setSplitStore(e.target.value)}
                                    type="text"
                                    className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <Button
                                  disabled={true}
                                  // disabled={splitresult?.isLoading}
                                  color='warning'
                                  variant="outlined"
                                  size="small"
                                  onClick={() => splitproducts({
                                    from_store: id,
                                    to_store: getSplitStore,
                                    limit: getSplitLimit,
                                    userId: userId,
                                    userToken: userToken,
                                    admin: admin
                                  })}
                                >
                                  Start
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <MenuItem disabled ></MenuItem>
                      </Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>

              </div>
              <div className="table_search_card">
                {/* {Number(id) === 9 &&
                  <div>
                    <Button
                      disabled={refetchData?.data?.refetch?.status || refetchresult?.isLoading}
                      variant="outlined"
                      size="small"
                      color='warning'
                      onClick={(() => refetchcreate({
                        store_id: id,
                        userId: userId,
                        userToken: userToken,
                        admin: admin
                      }))}
                    >
                      Refetch products
                    </Button>
                  </div>
                } */}
                <div style={{ margin: '0px 10px' }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpen(true)}
                  >
                    Add New
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={`/store/${id}/import`}
                  >
                    Add Products CSV
                  </Button>
                  {/* <Button
                    // style={{background: '#4e5053'}}
                    style={{ border: '0.5px solid #4e5053', color: '#4e5053' }}
                    variant="outlined"
                    size="small"
                    onClick={() => imageUpdate()}>
                    Import CSV
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* {Number(id) === 9 &&
        <>
          {refetchData?.data?.refetch &&
            <div className="row" style={{ margin: 0, padding: 0 }}>
              <div className="col-md-12" style={{ padding: 0 }}>
                <div className="storeCardB">
                  <div className="updateButton">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            size='small'
                            color='warning'
                            variant='outlined'
                            style={{ fontSize: '1rem', textTransform: 'capitalize' }}>
                            {refetchData?.data?.refetch?.status ? 'Inprogress' : 'Aborted'}
                            <div style={{ margin: '0px 10px', display: 'flex', alignItems: 'center' }}>
                              <ArrowForwardIosIcon style={{ fontSize: '1rem' }} />
                            </div>
                            {refetchData?.data?.refetch?.processed}
                          </Button>
                          {refetchData?.data?.refetch?.status &&
                            <div style={{ margin: '0px 10px' }}>
                              <Button
                                disabled={refetchupdateresult?.isLoading}
                                size='small'
                                color='error'
                                variant='outlined'
                                style={{ fontSize: '1rem', textTransform: 'capitalize' }}
                                onClick={(() => refetchupdate({
                                  store_id: id,
                                  userId: userId,
                                  userToken: userToken,
                                  admin: admin,
                                  progress: refetchData?.data?.refetch?.progress,
                                  limit: refetchData?.data?.refetch?.limit,
                                  processed: refetchData?.data?.refetch?.processed,
                                }))}
                              >
                                Abort
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="table_search_card">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      } */}

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th>GMC</th>
                </tr>
              </thead>
              {/* {suppliersData?.isLoading &&
                TableSkeleton(8)
              } */}
              <tbody>
                {suppliers?.map((item, i) =>
                  <tr>
                    <td>{item.pid}</td>
                    <td style={{ textAlign: 'center' }}>

                      {item.images ?
                        <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
                        <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      }
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => handleOpenEdit(item)}
                        >
                          <EditIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <IconButton
                          onClick={() => window.open(`https://www.desertcart.com/products/${item.pid}`, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '12px' }} />
                        </IconButton>

                        <Link to={`/product/${item.id}`} style={{ whiteSpace: 'initial' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.currency} {item.price}</td>
                    <td>{item.country}</td>
                    <td>
                      {item.google_merchant === 1 ? 'Ture' : 'False'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {suppliers?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }
        </div>
      </div>
      <br /><br />
      {!suppliersData?.isLoading &&
        <>
          {suppliersData?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{suppliersData?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}

export default Store;
