import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const actionApi = createApi({

  reducerPath: 'actionApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Action'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({
 
    importFile: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        return {
          url: `action/products`,
          method: 'POST',
          body: formData,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            // 'Content-Type': 'multipart/form-data',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'X-Merchant-Id': data.merchant_id
          }
        }
      },
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['Action'],
    }),


    fetchStoreProductsCashed: builder.query({
      query: (data) => ({
        url: `action/products/${data.id}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      keepUnusedDataFor: 86400000,
      providesTags: ['Action'],
    }),

  }),

})

export const { useFetchStoreProductsCashedQuery, useImportFileMutation } = actionApi