import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal, Spinner } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useImportFileMutation, useFetchStoreProductsCashedQuery } from '../../services/products';
import { useGetStoreQuery } from '../../services/store';
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TableSkeleton from '../TableSkeleton';
import io from 'socket.io-client';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CSVLink, CSVDownload } from 'react-csv';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};



const socket = io(process.env.REACT_APP_API_CONNECT);

function ProductsImport() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { storeId } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const storeProductsInfo = useFetchStoreProductsCashedQuery({ id: storeId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const storeProducts = storeProductsInfo?.data?.results;

  const storesInfo = useGetStoreQuery({ id: storeId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const store = storesInfo?.data?.store;

  const [updatefile, updatefileresult] = useImportFileMutation()
  const [getUpdateImage, setUpdateImage] = useState();
  function updateBookImage() {
    updatefile({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      store_id: storeId,
    })
  }

  useEffect(() => {

  }, []);


  const [progress, setProgress] = useState({ progress: 0, type: '', in_progress: false });
  useEffect(() => {
    socket.on(`csv-file-uploading`, (data) => {
      setProgress(data?.results);
    });
    return () => {
      socket.off(`csv-file-uploading`);
    };
  }, []);

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };


  function buttonDisabled() {
    updatefileresult.isLoading || getUpdateImage ? false : true
    if (updatefileresult.isLoading) {
      return true
    } else if (!getUpdateImage) {
      return true
    } else if (progress?.in_progress) {
      return true
    } else {
      return false
    }
  }



  const csvData = [
    ['title',
      'image_urls',
      'price_in_usd',
      'brand',
      'category',
      'weight_in_grams',
      'main_image',
      'country', 'uid',
      'in_stock',
      'condition',
      'delivery_days',
      'qty',
      'google_merchant',
      'features',
      'details',
      'variants',
      'current_variant',
      'specifications',
      'status'],
    [
      'product title',
      'https://microless.com/cdn/products/ac6258f5518377337ffa2c865096b921-md.jpg,https://microless.com/cdn/products/5a7b1eec58f28385835cd0b931e5ebc2-md.jpg',
      '200',
      'AMD',
      'laptop',
      '100',
      'https://prod-admin-images.s3.ap-south-1.amazonaws.com/WbjerLzUeW0cgVsOq5zQ/product/Ix1600.png',
      'AE',
      '119541',
      1,
      'New',
      2,
      10,
      0,
      ['features1', 'fetaures2'],
      'product deatils',
      `[{'129009': {'Generation:': '14th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}, {'129006': {'Generation:': '14th Gen', 'Series:': 'Core i5', 'Product Line Suffix:': 'K'}}, {'129007': {'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'KF'}}, {'112013': {'Generation:': '13th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}]`,
      `{'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'K'}`,
      `{'Brand': 'Intel', 'Model': 'i9-14900K Box', 'Microless SKU': '129005', 'Best Sellers Rank': '#2 inCPUs / Processors', 'Date first available': '18 October, 2023', 'Shipping Weight': '0.30 Kg', 'Shipping Dimensions': '6.50 cm x 15.00 cm x 16.51 cm'}`,
      0]
  ];




  return (
    <div className="tabel_cards">
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button onClick={handleBack}><ArrowBackOutlinedIcon /></Button>
                  <div style={{ margin: '0px 10px' }}> Store Name: {store?.name}</div>
                </div>
                <div className="Order-Pagination">
                  Store Country: {store?.country}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="row" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
            <div className="col-md-6" style={{ padding: 0 }}>
              <div style={{ background: '#fff', padding: '20px', border: '1px solid #eee' }}>
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '1.2rem', fontWeight: 400, textAlign: 'center', padding: '20px 0px 10px 0px' }}>Import CSV</div>
                    <CSVLink data={csvData} filename={"thwifty-products.csv"} target="_blank">
                      <Button
                        size="small"
                        style={{ width: 'auto', textTransform: 'capitalize', fontWeight: 400 }}
                        onClick={updateBookImage}
                      >
                        Download CSV Sample
                      </Button>
                    </CSVLink>
                  </div>
                  <div className="col-md-12">
                    {updatefileresult?.error?.data?.error &&
                      <div className='alert alert-danger' style={{ textAlign: 'center' }}>
                        {updatefileresult?.error?.data?.error}
                      </div>
                    }
                  </div>
                  <br />
                  {progress?.type &&
                    <div className="col-md-12" style={{ display: 'block' }}>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress?.progress} />
                      </Box>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textTransform: 'capitalize', fontSize: '1rem', fontWeight: '600' }}>
                          {progress?.type}
                        </div>
                        {progress?.in_progress &&
                          <div style={{ margin: '0px 10px', display: 'flex', alignItems: 'center' }}>
                            <Spinner
                              style={{ width: '1rem', height: '1rem', fontSize: '5px' }}
                              animation="border"
                            />
                          </div>
                        }
                      </div>
                      <br />
                    </div>
                  }
                  {storeProducts &&
                    <div className="col-md-12" style={{ display: 'block' }}>
                      <div style={{ fontSize: '1rem', fontWeight: '600', padding: '10px 0px 10px 0px' }}>Last updated</div>
                      <div className="row">
                        <div className="col-md-6">
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>File Name:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.file_name}</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Progress:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.progress}%</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Rows:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.limit}</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Processed:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.processed}</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Chunk Count:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.chunk_count}</Tag>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Chunk Size:</div>
                            <Tag style={{ width: '100%' }}>{storeProducts?.chunk_size}</Tag>
                          </div>
                        </div>

                      </div>
                      <br />
                    </div>
                  }
                  <div className="col-md-12">
                    <div className="form-group">
                      <input multiple type="file" className="form-control dlrdflInput"
                        onChange={(e) => setUpdateImage(e.target.files[0])} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group" style={{ textAlign: 'center' }}>
                      <Button
                        color="info"
                        variant="contained"
                        size="medium"
                        style={{ width: '100%', height: '45px' }}
                        disabled={buttonDisabled()}
                        onClick={updateBookImage}>
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <br /><br />

      {!storeProductsInfo?.isLoading &&
        <>
          {storeProductsInfo?.error?.originalStatus === 403 &&
            <div className="row reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
              <div className="col-md-6" style={{ padding: 0 }}>
                <div className='alert alert-warning' style={{ textAlign: 'center' }}>{storeProductsInfo?.error?.data}</div>
              </div>
            </div>
          }
        </>
      }
    </div >
  );
}

export default ProductsImport;
